<template>
    <div class="text-sm breadcrumbs">
        <ul>
            <template v-if="!showOnlyCustomCrumbs">
                <li v-for="breadcrumb in breadcrumbList" :key="breadcrumb.name" class="flex items-center gap-2 text-primary capitalize font-bold">
                    <!-- <font-awesome-icon v-if="crumb.icon" :icon="crumb.icon" /> -->
                    {{ breadcrumb.name }}
                </li>
            </template>
            <li v-for="breadcrumb in customCrumbs" :key="breadcrumb.name" class="flex items-center gap-2 text-primary capitalize font-bold">
                <!-- <font-awesome-icon v-if="crumb.icon" :icon="crumb.icon" /> -->
                <span class="block  max-w-xs truncate "  :title="breadcrumb.name" >

                    {{ breadcrumb.name }}
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Breadcrumb",
    data() {
        return {
            breadcrumbList: [],
            // customCrumbs: null,
        };
    },
    props: {
        showOnlyCustomCrumbs: {
            type: Boolean,
            default: false,
        },
        customCrumbLists: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        customCrumbs() {
            // if (this.showOnlyCustomCrumbs) {
            return this.customCrumbLists;
            // } else {
            //     return this.$route.meta.customCrumbs || null;
            // }
        },
    },
    mounted() {
        this.updateList(); /* this.updateDynamicRoute() */
    },
    watch: {
        $route() {
            this.updateList(); /* this.updateDynamicRoute() */
        },
    },
    methods: {
        routeTo(pRouteTo) {
            if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link);
        },
        updateList() {
            this.breadcrumbList = this.$route.meta.breadcrumb;
        },
        // updateDynamicRoute(){ this.customCrumbs = this.$route.meta.customCrumbs },
    },
};
</script>

<style>
li:before {
    color: black;
}
.breadcrumbs > ul > li + ::before {
    margin-left: 1rem;
}
</style>
